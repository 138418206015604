
/* colors */

$cal-date-bg-color: #ffaf24;
$cal-date-fg-color: #444;


/* typography */

.kicker {
    font-size:1rem;
    color:#666;
}


/* block styles */

.has-margin-top { margin-top:1rem; }
.has-margin-right { margin-right:1rem; }
.has-margin-bottom { margin-bottom:1rem; }
.has-margin-left { margin-left:1rem; }
.has-margin-top-2x { margin-top:2rem; }
.has-margin-top-3x { margin-top:3rem; }
.has-margin-bottom-2x { margin-bottom:2rem; }
.has-margin-bottom-3x { margin-bottom:3rem; }

.has-padding { padding:1rem; }

.has-max-width-100 { max-width: 100px; }
.has-max-width-200 { max-width: 200px; }
.has-max-width-300 { max-width: 300px; }
.has-max-width-400 { max-width: 400px; }
.has-max-width-500 { max-width: 500px; }
.has-max-width-600 { max-width: 600px; }
.has-max-width-700 { max-width: 700px; }
.has-max-width-800 { max-width: 800px; }
.has-max-width-900 { max-width: 900px; }
.has-max-width-1000 { max-width: 1000px; }
.has-max-width-1100 { max-width: 1100px; }
.has-max-width-1200 { max-width: 1200px; }

$radius-border-curve: 4px;
.is-radius { border-radius: $radius-border-curve; }


/* custom element styles */

.root.container {
    max-width:840px;    /* divisable by both 12 and 16 */
}

.cal-date {
    display:inline-block;
    width:55px;
    text-align:center;
    border:1px solid $cal-date-bg-color;
    border-radius: $radius-border-curve;

    .mon {
        display:block;
        font-size: 0.85rem;
        font-weight: bold;
        text-transform: uppercase;
        background:$cal-date-bg-color;
        color:$cal-date-fg-color;
        padding:2px 0;
    }
    .day {
        display:block;
        font-size:1.6rem;
        font-weight: 500;
        padding:2px;
        color:$cal-date-fg-color;
    }
}

.navbar-brand .ident {
    color: $cal-date-bg-color;
    text-shadow: 1px 1px 2px #aaa;

    &:visited,
    * {
        color: $cal-date-bg-color;
    }
}
